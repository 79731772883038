import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle, Route } from '@angular/router'

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    // Updated with the help of the following post in github
    // https://github.com/angular/angular/issues/44383#issuecomment-996361898

    private handlers: Map<Route, DetachedRouteHandle> = new Map();

    // Determines if this route (and its subtree) should be detached to be reused later
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // By default all routes will be saved and reused. Any exceptions will need a data config
        // added to the route definition (see login route as an example).
        return true || route.data['reuseRoute'];
    }

    // Stores the detached route.
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if (!route.routeConfig) return;
        this.handlers.set(route.routeConfig, handle);
    }

    // Determines if this route (and its subtree) should be reattached
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.handlers.get(route.routeConfig);
    }

    // Retrieves the previously stored route
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (!route.routeConfig || !this.handlers.has(route.routeConfig)) return null;
        let handler = this.handlers.get(route.routeConfig);
        return handler || null;
    }

    // Determines if a route should be reused
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}