import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';

import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MultiSelectModule,
    DropdownModule,
    ProgressSpinnerModule,
    FormsModule,
    ButtonModule
  ],
  exports: [
  ],
  providers: []
})
export class CoreModule { }
