import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, EMPTY, catchError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = this.authService.userData;
    let token = user.getIdToken();
    if (!token) {
      console.log('User token missing or expired. Logging out.')
      this.authService.logout();
      return EMPTY;
    } else {
      // Setting auth headers on all outgoing HTTP requests
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        }
      });
      return next.handle(request)
        .pipe(
          // Note: We can have a global retry on all HTTP requests with the line below.
          // retry(1),
          catchError((error: HttpErrorResponse) => {

            // NOTE: This will only handle responses that are coming back from the server as errors.
            // Our backend is sometimes returning a 200 OK but the response data will be an error object.
            // I'm not going to handle these situations as we should correct our backend instead.

            let errorMessage = error.error instanceof ErrorEvent 
              ? error.error.message 
              : `Error Code: ${error.status}\nMessage: ${error.error.message}`;
            window.alert(errorMessage);
            console.log(errorMessage);
            return EMPTY;
          })
        );
    }
  }
}
