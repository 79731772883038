import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { UserPermissions } from 'src/app/.types/interfaces/user-permissions';
import { environment } from '../../../../src/environments/environment';
import { User } from '../../.types/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User = new User();
  public allUserPermissions: UserPermissions[] = [];
  public userPermissions: UserPermissions | null = null;
  private loginSessionDetails?: AuthenticationDetails;
  private cognitoUser?: CognitoUser;
  private passwordStrength: string = `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$`;

  private forgotPasswordSource = new Subject<void>();
  private verifyNewPasswordSource = new Subject<string>();
  private verifyNewPasswordChangedSource = new Subject<void>();
  private userAuthenticatedSource = new Subject<void>();
  private userAutoAuthenticatedSource = new Subject<void>();
  private authenticationFailedSource = new Subject<string>();
  private newPasswordRequiredSource = new Subject<void>();
  private requirePasswordChangedSource = new Subject<void>();
  private userLoggedOutSource = new Subject<void>();
  private changePasswordRequestedSource = new Subject<void>();
  private changePasswordSuccessfulSource = new Subject<void>();
  private loginResetSource = new Subject<void>();

  forgotPassword$ = this.forgotPasswordSource.asObservable();
  verifyNewPassword$ = this.verifyNewPasswordSource.asObservable();
  verifyNewPasswordChanged$ = this.verifyNewPasswordChangedSource.asObservable();
  userAuthenticated$ = this.userAuthenticatedSource.asObservable();
  userAutoAuthenticated$ = this.userAutoAuthenticatedSource.asObservable();
  authenticationFailed$ = this.authenticationFailedSource.asObservable();
  newPasswordRequired$ = this.newPasswordRequiredSource.asObservable();
  requirePasswordChanged$ = this.requirePasswordChangedSource.asObservable();
  userLoggedOut$ = this.userLoggedOutSource.asObservable();
  changePasswordRequest$ = this.changePasswordRequestedSource.asObservable();
  changePasswordSuccessful$ = this.changePasswordSuccessfulSource.asObservable();
  loginReset$ = this.loginResetSource.asObservable();

  constructor(private router: Router,
    private http: HttpClient) { }

  get passwordStrengthExpression(): string {
    return this.passwordStrength;
  }

  get userData(): User {
    return this.user;
  }

  public authenticateCognitoUser(email: string, password: string) {
    let me = this;
    this.cognitoUser = this.getCognitoUser(email);
    this.loginSessionDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    this.cognitoUser.authenticateUser(this.loginSessionDetails, {
      onSuccess: function (result) {
        let accessToken = result.getAccessToken();

        // Normal expiration date from amazon
        let expDate = new Date(0);
        expDate.setSeconds(accessToken.payload['exp']);

        // For testing a short hard-coded session expiration uncomment the lines below
        // and comment the lines above.

        // let expDate = new Date();
        // expDate.setSeconds(testDate.getSeconds() + 60);

        me.user = new User({
          name: accessToken.payload['username'],
          email: email,
          accessToken: accessToken.getJwtToken(),
          accessTokenExpirationDate: expDate,
          idToken: result.getIdToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken()
        });
        localStorage.setItem('userData', JSON.stringify(me.user));
        me.userAuthenticatedSource.next();
      },
      onFailure: function (err: any): void {
        console.error(err.message);
        me.authenticationFailedSource.next(err.message);
      },
      newPasswordRequired: function () {
        me.newPasswordRequiredSource.next();
      }
    });
  }

  sendVerificationCode(email: string): Promise<any> {
    let me = this;
    const cognitoUser = this.getCognitoUser(email);
    return new Promise(function(resolve, reject){
      cognitoUser.forgotPassword( {
          onSuccess: (data) => { 
            me.verifyNewPasswordSource.next(email);
            resolve(data); 
          },
          onFailure: (err) => { reject(err); }
        });
    });
  }

  updatePasswordWithVerification(email: string, verification: string, newPassword: string): Promise<any> {
    let me = this;
    const cognitoUser = this.getCognitoUser(email);
    return new Promise(function(resolve, reject){
      cognitoUser.confirmPassword(verification, newPassword, {
          onSuccess: (data) => { 
            me.verifyNewPasswordChangedSource.next();
            resolve(data); 
          },
          onFailure: (err) => { reject(err); }
        });
    });
  }

  requestChangePassword(): void {
    this.router.navigate(['/login']);
    this.changePasswordRequestedSource.next();
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    let me = this;
    const loginDetails = this.loginSessionDetails;
    if (!loginDetails) throw new Error('Missing Login Details');
    const cognitoUser = this.cognitoUser;
    if (!cognitoUser) throw new Error('User not authenticated');
    return new Promise(function(resolve, reject){
      cognitoUser.changePassword(oldPassword, newPassword, (err, success) => {
        if (err) { 
          reject(err);      
        }  else  {
          me.changePasswordSuccessfulSource.next();
          resolve(success);
        }
      });
    });
  }

  forgotPassword(): void {
    this.forgotPasswordSource.next();
  }

  requireNewPasswordUpdate(newPassword: string): Promise<any> {
    let me = this;
    const loginDetails = this.loginSessionDetails;
    if (!loginDetails) throw new Error('Missing Login Details');
    const cognitoUser = this.getCognitoUser(loginDetails?.getUsername());
    return new Promise(function(resolve, reject){
      cognitoUser.authenticateUser(loginDetails, {
        onSuccess: (data) => { 
          // We shouldn't get here
          resolve(data); 
        },
        onFailure: (err) => { reject(err) },
        newPasswordRequired: () => {
          cognitoUser.completeNewPasswordChallenge(newPassword, { name: loginDetails.getUsername() }, {
              onSuccess: (data) => { 
                me.requirePasswordChangedSource.next();
                resolve(data); 
              },
              onFailure: (error) => { reject(error); }
          });
        }
      });
    });   
  }

  autoLogin() {
    const userData = localStorage.getItem('userData');
    if (!userData) return;
    let data = JSON.parse(userData);
    const user = new User({
      name: data.name,
      email: data.email,
      accessToken: data.accessToken,
      accessTokenExpirationDate: data.accessTokenExpirationDate,
      idToken: data.idToken,
      refreshToken: data.refreshToken
    });
    if (!user.getAccessToken()) {
      // User Access Token expired. Clear localStorage. User must login normally.
      localStorage.removeItem('userData');
      return;
    }

    // TODO: Find a way to decode the jwt ID Token to retrieve the user group from the payload. We need this to give admin access
    // to users. You can get the payload in the response when the user logs in but we want to allow the user to reload the
    // page and not reload the entire application. We also can't store user admin group in local storage for security reasons.
    // We do store the tokens in localStorage though.

    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html

    this.user = user
    this.userAutoAuthenticatedSource.next();
  }

  loginReset() {
    this.loginResetSource.next();
  }

  logout() {
    this.userLoggedOutSource.next();
    this.cognitoUser = undefined;
    this.loginSessionDetails = undefined;
    this.user = new User();
    this.router.navigate(['/login']);
    localStorage.removeItem('userData');
  }

  private getCognitoUser(email: string) {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId
    }
    let userPool = new CognitoUserPool(poolData);
    return new CognitoUser({
      Username: email,
      Pool: userPool
    });
  }

  getPermission(route: ActivatedRouteSnapshot): boolean {
    let routeUrl = route.url.toString();
    console.log(routeUrl);
    if(routeUrl === 'admin' && this.userPermissions?.admin){
      return true;
    } else if (routeUrl === 'my-deposit-plan' && this.userPermissions?.deposit_plan){
      return true;
    } else if (routeUrl !== 'admin' && routeUrl !== 'my-deposit-plan') {
      return true;
    } else {
      return false;
    }
  }

  createUser(newUser: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const jsonNewUser = JSON.stringify(newUser);
    const results = this.http.post<any[]>('/api/users', jsonNewUser, httpOptions);
    return results;
  }

  deleteUser(deleteUser: string) {
    const payload = { name: deleteUser };
    const options = {
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    const results = this.http.delete<any[]>('/api/users/' + deleteUser, options);
    return results;
  }

  validateAndFormatPhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    if (cleaned.length < 10) {
      // Invalid phone number format
      return null;
    }
    const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/); // Match and capture digits
    if (!match) {
      // Invalid phone number format
      return null;
    }
    return `+1${match[1]}${match[2]}${match[3]}`; // Format phone number with country code
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}

