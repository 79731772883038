import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){ }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
      const user = this.authService.userData;
      if (!user.getAccessToken()) {
        this.authService.logout();
        return false;
      } else if(this.authService.getPermission(route)) {
        return true;
      } else {
        return false;
      }
  }
}
